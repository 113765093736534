var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient__results__comparison"},[_c('div',{staticClass:"patient__results__comparison--banner chart-card mb-5"},[_c('div',{staticClass:"patient__results__comparison--banner__title"},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.compare-acquisition')))]),_c('VBtn',{staticClass:"ml-auto",attrs:{"loading":_vm.isPending,"disabled":_vm.isPending ||
          !_vm.firstAnalysis ||
          !_vm.secondAnalysis ||
          !_vm.values.first ||
          !_vm.values.second ||
          (!!_vm.firstAnalysis.podomigration && !_vm.secondAnalysis.podomigration) ||
          (!_vm.firstAnalysis.podomigration && !!_vm.secondAnalysis.podomigration),"color":"primary","depressed":"","rounded":"","small":""},on:{"click":function($event){return _vm.openExport()}}},[_vm._v(_vm._s(_vm.$t('commons.actions.export.pdf')))])],1),_c('span',{staticClass:"caption mb-2"},[_vm._v(_vm._s(_vm.$t('commons.sentences.select-acquisitions-compare')))]),(_vm.analysisList)?_c('div',{staticClass:"patient__results__comparison--banner__select"},[_c('VSelect',{attrs:{"items":_vm.firstAnalysisByType,"label":_vm.$tc('commons.standards.acquisition', 1),"disabled":_vm.isPending,"item-value":"cuid","return-object":"","hide-details":"","outlined":"","rounded":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row py-2"},[_c('VListItemAvatar',{staticClass:"ma-0 mr-3",attrs:{"color":_vm.getAnalysisIconBgColor(item._scenario.key, _vm.isDegradedAnalysis(item)),"size":"30"}},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(item._scenario.key).icon,"library":_vm.getIconByScenario(item._scenario.key).library,"color":"white","size":"8"}})],1),(item.podomigration)?_c('VListItemAvatar',{staticClass:"ma-0 mr-3",attrs:{"color":"white","size":"20"}},[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}})]):_vm._e(),_c('VListItemContent',{staticClass:"py-0"},[_c('VListItemTitle',{staticClass:"mb-0"},[(!item._scenario.key.startsWith('jumping'))?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t( ("commons.sentences." + (item._scenario.key.startsWith('walking') ? 'walk-analysis' : 'run-analysis')) ))+" ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(("commons.standards." + (_vm.getMode(item._scenario.key)))))+" ")])]),_c('VListItemSubtitle',[_c('span',{staticClass:"caption grey--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format('LL'))+" - "+_vm._s(_vm.$moment(item.createdAt).format('HH:mm'))+" ")])])],1)],1)]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:("patient__results__comparison--banner__select__analysisType mr-2 " + (_vm.getAnalysisIconBgColor(
              item._scenario.key,
              _vm.isDegradedAnalysis(item)
            )))},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(item._scenario.key).icon,"library":_vm.getIconByScenario(item._scenario.key).library,"color":"white","size":"16"}})],1),(item.podomigration)?_c('div',{staticClass:"patient__results__comparison--banner__select__analysisType"},[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}})]):_vm._e(),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format('LL'))+" • "+_vm._s(_vm.$moment(item.createdAt).format('HH:mm'))+" ")])]}},(_vm.analysisListHasNext)?{key:"append-item",fn:function(){return [_c('VListItem',{staticClass:"caption text-center",attrs:{"disabled":_vm.isPending},on:{"click":function($event){return _vm.loadMore()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.load-more'))+"...")])])]},proxy:true}:null],null,true),model:{value:(_vm.firstAnalysis),callback:function ($$v) {_vm.firstAnalysis=$$v},expression:"firstAnalysis"}}),_c('span',{staticClass:"caption mx-3"},[_vm._v(_vm._s(_vm.$t('commons.standards.and')))]),_c('VSelect',{attrs:{"items":_vm.secondAnalysisByType,"label":_vm.$tc('commons.standards.acquisition', 1),"disabled":_vm.isPending || !_vm.firstAnalysis,"item-value":"cuid","return-object":"","hide-details":"","open-on-clear":"","outlined":"","rounded":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row py-2"},[_c('VListItemAvatar',{staticClass:"ma-0 mr-3",attrs:{"color":_vm.getAnalysisIconBgColor(item._scenario.key, _vm.isDegradedAnalysis(item)),"size":"30"}},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(item._scenario.key).icon,"library":_vm.getIconByScenario(item._scenario.key).library,"color":"white","size":"8"}})],1),(item.podomigration)?_c('VListItemAvatar',{staticClass:"ma-0 mr-3",attrs:{"color":"white","size":"20"}},[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}})]):_vm._e(),_c('VListItemContent',{staticClass:"py-0"},[_c('VListItemTitle',{staticClass:"mb-0"},[(!item._scenario.key.startsWith('jumping'))?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t( ("commons.sentences." + (item._scenario.key.startsWith('walking') ? 'walk-analysis' : 'run-analysis')) ))+" ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t(("commons.standards." + (_vm.getMode(item._scenario.key)))))+" ")])]),_c('VListItemSubtitle',[_c('span',{staticClass:"caption grey--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format('LL'))+" - "+_vm._s(_vm.$moment(item.createdAt).format('HH:mm'))+" ")])])],1)],1)]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("patient__results__comparison--banner__select__analysisType " + (_vm.getAnalysisIconBgColor(
              item._scenario.key,
              _vm.isDegradedAnalysis(item)
            )) + " mr-2")},[_c('GlobalIcon',{attrs:{"icon":_vm.getIconByScenario(item._scenario.key).icon,"library":_vm.getIconByScenario(item._scenario.key).library,"color":"white","size":"16"}})],1),(item.podomigration)?_c('div',{staticClass:"patient__results__comparison--banner__select__analysisType"},[_c('img',{staticStyle:{"height":"20px"},attrs:{"src":require('@/assets/images/logo-migrated.svg'),"alt":"migrated"}})]):_vm._e(),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format('LL'))+" • "+_vm._s(_vm.$moment(item.createdAt).format('HH:mm'))+" ")])]}},(_vm.analysisListHasNext)?{key:"append-item",fn:function(){return [_c('VListItem',{staticClass:"caption text-center",attrs:{"disabled":_vm.isPending},on:{"click":function($event){return _vm.loadMore()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.load-more'))+"...")])])]},proxy:true}:null],null,true),model:{value:(_vm.secondAnalysis),callback:function ($$v) {_vm.secondAnalysis=$$v},expression:"secondAnalysis"}})],1):_vm._e()]),_c('div',{staticClass:"patient__results__comparison--results"},[(_vm.isPending)?_c('div',{staticClass:"d-flex justify-center align-center mt-10"},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary","width":"2","size":"16"}}),_c('span',{staticClass:"caption ml-2"},[_vm._v(_vm._s(_vm.$t('commons.standards.loading'))+"...")])],1):(!_vm.values.first || !_vm.values.second)?_c('div',[_c('div',{staticClass:"d-flex flex-column justify-center align-center pa-10"},[_c('GlobalIcon',{attrs:{"library":"light","icon":"magnifying-glass-chart","size":30,"color":"grey"}}),_c('span',{staticClass:"text-center grey--text body mt-2"},[_vm._v(" "+_vm._s(_vm.$t('commons.sentences.select-acquisitions-compare'))+" ")])],1)]):(_vm.firstAnalysis && _vm.secondAnalysis && _vm.values.first && _vm.values.second)?[(
          (_vm.firstAnalysis.podomigration && !_vm.secondAnalysis.podomigration) ||
          (!_vm.firstAnalysis.podomigration && _vm.secondAnalysis.podomigration)
        )?_c('div',{staticClass:"podomigration-alert mb-2"},[_c('GlobalIcon',{staticClass:"mr-2",attrs:{"library":"light","icon":"lightbulb-exclamation","color":"primary","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('podomigration.analysis.comparison-warning'))+" ")])],1):[(_vm.firstAnalysis._scenario.key.startsWith('walking') && !_vm.haveDegradedWalkingAnalysis)?_c('div',{staticClass:"results-comparison"},[_c('PatientResultsComparisonWalking',{attrs:{"first":_vm.values.first,"second":_vm.values.second}})],1):_vm._e(),(_vm.firstAnalysis._scenario.key.startsWith('walking') && _vm.haveDegradedWalkingAnalysis)?_c('div',{staticClass:"results-comparison"},[_c('PatientResultsComparisonWalkingDegraded',{attrs:{"first":_vm.values.first,"second":_vm.values.second}})],1):_vm._e(),(_vm.firstAnalysis._scenario.key.startsWith('running'))?_c('div',{staticClass:"results-comparison"},[_c('PatientResultsComparisonRunning',{attrs:{"first":_vm.values.first,"second":_vm.values.second}})],1):_vm._e(),(_vm.firstAnalysis._scenario.key.startsWith('jumping'))?_c('div',{staticClass:"results-comparison"},[_c('PatientResultsComparisonJumping',{attrs:{"first":_vm.values.first,"second":_vm.values.second}})],1):_vm._e()]]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }