












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardPronationSupinationAngles from '@/components/charts/standard/ChartStandardPronationSupinationAngles.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingGroupStepsAngles',
  components: {
    ChartStandardPronationSupinationAngles
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup() {
    const angles = ref([
      { heelStrike: 'foot_in' },
      { flatFoot: 'FFI' },
      { heelOff: 'FFO' },
      { toesOff: 'foot_out' }
    ]);
    return { angles };
  }
});
